<script>
// import AppCoinProduct from '@/views/coin/product/AppTournamentProduct.vue';
// import AppCoinBonus from '@/views/coin/product/AppTournamentBonus.vue';
import router from '@/router';

export default {
    name: 'index',
    components: {
        // AppCoinBonus,
        // AppCoinProduct
    },
    data() {
        return {
            routerName: 'tournament-product'
        };
    },
    methods: {
        redirectChild(name) {
            return router.push({ name: name });
        }
    },
    mounted() {
        this.routerName = this.$route.name || 'tournament-product';
    }
};
</script>

<template>
    <div class="tabs_block">
        <b-tabs align="left">
            <b-tab
                :active="routerName === 'tournament-product'"
                :title="$t('navigation.coin_product')"
                @click="redirectChild('tournament-product')"
            >
                <!--                <AppCoinProduct />-->
            </b-tab>
            <b-tab
                :active="routerName === 'tournament-product-bonus'"
                :title="$t('navigation.coin_bonus')"
                @click="redirectChild('tournament-product-bonus')"
            >
                <!--                <AppCoinBonus />-->
            </b-tab>
        </b-tabs>

        <router-view />
    </div>
</template>

<style scoped></style>
